import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vueGtag from "vue-gtag-next";
/*
createApp(App).use(vueGtag, {
        config: {id: "G-CGNXJVN36Y"}
}).mount("#app");

createApp(App).use(router).mount('#app')
*/
const app = createApp(App);

app.use(router)
app.use(vueGtag, {
    property: {
        id: "G-CGNXJVN36Y"
    }
});

app.mount('#app')
