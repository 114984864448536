import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'
import About from '../views/About.vue'
import News from '../views/News/News.vue'
import NewsDetails from '../views/News/NewsDetails'
import NewsPolitica from '../views/News/NewsPolitica'
import NewsEconomice from '../views/News/NewsEconomice'
import NewsExterne from '../views/News/NewsExterne'
import NewsSport from '../views/News/NewsSport'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: About
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/politica',
    name: 'NewsPolitica',
    component: NewsPolitica
  },
  {
    path: '/externe',
    name: 'NewsExterne',
    component: NewsExterne
  },
  {
    path: '/sport',
    name: 'NewsSport',
    component: NewsSport
  },
  {
    path: '/economie',
    name: 'NewsEconomice',
    component: NewsEconomice
  },
  {
    path: '/news/:id',
    name: 'NewsDetails',
    component: NewsDetails,
    props: true
  },
  //redirect simpu
  {
    path:'/all-news',
    redirect: 'News'
  },
  //redirect all not found
  {
    path:'/:catchAll(.*)',
    name:'Notfound',
    component:NotFound


  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
