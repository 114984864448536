<template>
    <h1>News</h1>
    <section class="articles">
    <div v-for="item in news" :key="item.id">
        
        
  <article>
    <div class="article-wrapper">
      <figure>
        <img :src="item.feedimg" alt=""> 
      </figure>
      <div class="article-body">
        <h2>{{ item.title }}</h2>
        <p>{{item.summary}}</p>
        <p>{{item.pubTime}}</p>
        <p>{{item.id}}</p>
        
      </div>
    </div>
  </article>
  
        <!--
        <router-link :to="{name: 'NewsDetails', params: {id: item.id}}">            
            
            <img :src="item.feedimg" width="200" alt=""> 
            <p>{{item.summary}}</p>
            <h1>{{item.pubTime}}</h1>

        </router-link>
        -->
    </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            news: []
        }
    },
    mounted(){
        fetch('https://msa.hobbyx.ro/news/users')
        .then (res => res.json())
        .then (data => this.news=data)
        .catch (err =>console.log(err.message))
    }
}
</script>

<style>

</style>