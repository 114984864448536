<template>
<div v-if="news">
   <h1>News details page</h1>
  <p> News  isd is {{ $route.params.id}} </p>
  <p>{{ news.link}}</p>
  <p>{{id}}</p>
</div>
<div v-else>
  <p>Loading....</p>
</div>
 
</template>

<script>
export default {
  props: ['id'],
  data () {
    return {
      news: null
    }
  },
  mounted(){
        fetch('https://msa.hobbyx.ro/news/users/' +this.id)
        .then (res => res.json())
        .then (data => this.news=data)
        .catch (err =>console.log(err.message))
    }
  
  //data () {
  //  is: this.$route.params.id
  //}

}
</script>

<style>

</style>